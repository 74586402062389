import React, { useEffect, useState } from 'react';
import Page from '@molecules/Page';

import { io } from 'socket.io-client';
import { generateToken, verifyToken } from '@/utils';

import { Home } from '@molecules/Slides';

const IndexPage = () => {
  const [stateSocket, setStateSocket] = useState(null);
  const [stateSlide, setStateSlide] = useState(0);
  useEffect(() => {
    const socketUrl = process.env.GATSBY_DISPLAY_SERVER;
    const newSocket = io(socketUrl);

    setStateSocket(newSocket);
    return () => newSocket.close();
  }, [setStateSocket]);

  const broadcastMSG = data => {
    if (stateSocket) {
      stateSocket.emit('update', generateToken(data));
    }
  };
  useEffect(() => {}, [stateSlide]);
  return (
    <Page broadcastMSG={broadcastMSG} stateSlide={stateSlide} setStateSlide={setStateSlide} footer={stateSlide !== 2 && stateSlide !== 3}>
      {
        {
          0: <Home headerIntro={true} />,
        }[stateSlide]
      }
    </Page>
  );
};
export default IndexPage;
